import React, { useMemo, createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const UserContext = createContext()

export const initialState = {
  username: '',
  email: '',
  accessToken: '',
  idToken: '',
  refreshToken: '',
  ethereumAddress: '',
  wallets: {},
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(initialState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    } else {
      localStorage.removeItem('user')
    }
  }, [user])

  const value = useMemo(
    () => ({ user, setUser, loading }),
    [user, setUser, loading]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
